<script setup lang="ts">
import { Locale } from '~/i18n/i18nConfig';

const { locale, setLocale } = useI18n();
const route = useRoute();
const getRouteBaseName = useRouteBaseName();
const { changeAlpLocale } = useAlpRouteUtils();

const options = [
    { locale: Locale.DE, label: 'Deutsch', icon: 'i-circle-flags-de' },
    { locale: Locale.EN, label: 'English', icon: 'i-circle-flags-en' },
    { locale: Locale.ES, label: 'Español', icon: 'i-circle-flags-es' },
    { locale: Locale.FR, label: 'Français', icon: 'i-circle-flags-fr' },
    { locale: Locale.IT, label: 'Italiano', icon: 'i-circle-flags-it' },
    { locale: Locale.PL, label: 'Polski', icon: 'i-circle-flags-pl' },
];

const modelValue = computed(() => {
    return options.find(option => option.locale === locale.value);
});

const changeLocale = ({ locale }: { locale: Locale }): void => {
    if (getRouteBaseName(route) === 'auctions-localizedAssetType-optionalParams') {
        changeAlpLocale(locale);
    } else {
        setLocale(locale);
    }
};
</script>

<template>
    <USelectMenu :model-value="modelValue" :options="options" @change="changeLocale">
        <template #default="{ open }">
            <UButton color="white" size="xl" variant="link" :ui="{ padding: { xl: 'pl-3.5 pr-0 md:px-3.5' } }">
                <template #leading>
                    <UIcon :name="modelValue!.icon" class="h-4 w-4" />
                </template>
                <span>{{ modelValue!.label }}</span>
                <template #trailing>
                    <UIcon name="i-mdi-chevron-down" class="text-2xl transition-transform" :class="[open && 'rotate-180 transform']" />
                </template>
            </UButton>
        </template>
        <template #option="{ option }">
            <span class="flex gap-1.5">
                <UIcon :name="option.icon" class="h-4 w-4" />
                <span>{{ option.label }}</span>
            </span>
        </template>
    </USelectMenu>
</template>
